//画面サイズごとの処理
$(window).on("load resize", function () {
  const w = $(window).width();
  deviceFunc(w);
});

const browser = browserCheck();
const os = osCheck();
const device = deviceCheck();
$("body").addClass(browser).addClass(os);

const href = $(location).attr("href");
let host = $(location).attr("hostname");
const ref = document.referrer;
const hash = $(location).attr("hash");

if (href.indexOf("/test/") > 0) {
  host = host + "/test";
}

$(document).ready(function () {
  //Menu
  var state = false;
  var scrollPos;
  $("#menu-btn").on("click", function () {
    $("#menu").toggleClass("open");
    if (state == false) {
      scrollPos = $(window).scrollTop();//現在のスクロール位置
      $('body').addClass('fixed').css('top',-scrollPos + 'px');
      state = true;
    } else {
      $("body").removeClass("fixed").css('top',0 + 'px');
      window.scrollTo( 0 , scrollPos );
      state = false;
    }
  });

  // <a href="#***">の場合、スクロール処理を追加
  $('a[href*="#"]').click(function () {
    if (state) {
      // メニューが開いている場合はスクロール位置をリセット
      $("#menu").removeClass("open");
      $("body").removeClass("fixed").css('top', 0 + 'px');
      window.scrollTo(0, scrollPos);
      state = false;
    }
    
    // ヘッダーの高さを事前に取得
    var h = $("header").outerHeight(); // 追加
    // adjust に代入するとヘッダーの高さを取得してズラせる。
    var adjust = -h; // 変更
    console.log(adjust);
    // スクロールの速度（ミリ秒）
    var speed = 600;
    // リンク先を取得してhrefという変数に代入
    var href = $(this).attr("href");
    // リンク先にidがある場合ターゲットとして値を取得
    var target = $(href == "#" || href == "" ? "html" : href);
    // ターゲットの位置を取得し、調整がある場合は位置の調整を行う
    var position = target.offset().top + adjust;
    console.log(target.offset().top);

    // スクロール実行
    $("body,html").animate({ scrollTop: position }, speed, "swing");

    if (state) {
      //ハンバーガーメニューを開いているときの挙動を考慮
      $("#menu").toggleClass("open");
      $("body").removeClass("fixed");
      state = false;
    }
    return false;
  });

  if ($(".key").length) {
    document.addEventListener("DOMContentLoaded", function () {
      checkScroll(); // 初期表示時のスクロール位置を確認
    });

    document.addEventListener("scroll", function () {
      checkScroll(); // スクロールイベントの際にチェック
    });

    //モーダル表示
    $(".privacy-link").click(function () {
      $("#privacy").addClass("is-show");
    });
    $("#privacy").click(function () {
      $("#privacy").removeClass("is-show");
    });
  }
});

function deviceFunc(w) {
  const bp = [559, 959, 1280];
  if (w > bp[1]) {
    //タブレット以上（PC）
  } else {
    //タブレット以下
  }
  if (w > bp[0]) {
    //スマートフォン以上
    $(".tel-wrap .tel-number").each(function () {
      $(this).unwrap();
    });
  } else {
    //スマートフォン以下
    $(".tel-number").each(function () {
      if (!$(this).parent(".tel-wrap").length) {
        const no = $(this).data("tel");
        $(this).wrap('<a href="tel:' + no + '" class="tel-wrap"></a>');
      }
    });
  }
}
/*ブラウザ判別*/
function browserCheck() {
  const ua = window.navigator.userAgent.toLowerCase();
  const av = window.navigator.appVersion.toLowerCase();
  if (ua.indexOf("edge") != -1) {
    return "edge";
  } else if (ua.indexOf("opera") != -1) {
    return "opera";
  } else if (ua.indexOf("msie") != -1 || ua.indexOf("trident") != -1) {
    $("body").addClass("ie");
    if (av.indexOf("msie 6.") != -1) {
      return "ie6";
    } else if (av.indexOf("msie 7.") != -1) {
      return "ie7";
    } else if (av.indexOf("msie 8.") != -1) {
      return "ie8";
    } else if (av.indexOf("msie 9.") != -1) {
      return "ie9";
    } else if (av.indexOf("msie 10.") != -1) {
      return "ie10";
    } else if (av.indexOf("rv:11.") != -1) {
      return "ie11";
    }
  } else if (ua.indexOf("chrome") != -1) {
    return "chrome";
  } else if (ua.indexOf("safari") != -1) {
    return "safari";
  } else if (ua.indexOf("gecko") != -1) {
    return "firefox";
  } else {
    return false;
  }
}

/*OS判別*/
function osCheck() {
  const ua = window.navigator.userAgent.toLowerCase();
  if (ua.indexOf("windows") != -1) {
    if (ua.indexOf("phone") != -1) {
      return "winphone";
    } else {
      return "win";
    }
  } else if (ua.indexOf("iphone") != -1) {
    return "iphone";
  } else if (ua.indexOf("ipad") != -1) {
    return "ipad";
  } else if (ua.indexOf("mac") != -1) {
    return "mac";
  } else if (ua.indexOf("android") != -1) {
    if (ua.indexOf("mobile") != -1) {
      if (ua.indexOf("A1_07") != -1) {
        return "tablet";
      } else if (ua.indexOf("SC-01C") != -1) {
        return "tablet";
      } else {
        return "android";
      }
    } else {
      return "tablet";
    }
  } else {
    return false;
  }
}

/*デバイス判別*/
function deviceCheck() {
  const ua = window.navigator.userAgent.toLowerCase();
  if (
    (ua.indexOf("windows") != -1 &&
      ua.indexOf("touch") != -1 &&
      ua.indexOf("tablet pc") == -1) ||
    ua.indexOf("ipad") != -1 ||
    (ua.indexOf("android") != -1 && ua.indexOf("mobile") == -1) ||
    (ua.indexOf("firefox") != -1 && ua.indexOf("tablet") != -1) ||
    ua.indexOf("kindle") != -1 ||
    ua.indexOf("silk") != -1 ||
    ua.indexOf("playbook") != -1
  ) {
    return "tab";
  } else if (
    (ua.indexOf("windows") != -1 && ua.indexOf("phone") != -1) ||
    ua.indexOf("iphone") != -1 ||
    ua.indexOf("ipod") != -1 ||
    (ua.indexOf("android") != -1 && ua.indexOf("mobile") != -1) ||
    (ua.indexOf("firefox") != -1 && ua.indexOf("mobile") != -1) ||
    ua.indexOf("blackberry") != -1
  ) {
    return "sp";
  } else {
    return "pc";
  }
}

function checkScroll() {
  if (window.scrollY > 670) {
    $(".gnavi").addClass("bg-white");
  } else {
    $(".gnavi").removeClass("bg-white");
  }
}
